<template>
    <v-container class="C1">
        <div @click="navigate('home')" class="logo pa-5">
          <img src="../assets/icon.png" alt="mvs logo" width="100px" height="80px">
        </div>
        <div class="logincard">
            <p class="signin">Sign Up</p>
            <v-form>
        <v-container>
        <v-row>
            <v-col
            cols="12"
            sm="12"
            >
           <v-text-field
             background-color='#c9ebff'
            shaped
            autofocus
            required
            :error-messages="fullnameErrors"
            @input="$v.fullname.$touch()"
            @blur="$v.fullname.$touch()"
            v-model="fullname"
            label="Fullname"
            filled
           ></v-text-field>
            </v-col>
        <v-col
            cols="12"
            sm="6"
            >

           <v-text-field
            background-color='#c9ebff'
           shaped
            required
            :error-messages="emailErrors"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
           v-model="email"
            label="Email Address"
            filled
           ></v-text-field>
            </v-col>
         <v-col
            cols="12"
            sm="6"
            class="mt-3"
            >
          <VuePhoneNumberInput default-country-code="NG" v-model="phoneNumber" @update="results = $event"/>
            </v-col>
             <v-col
            cols="12"
            sm="6"
            >

           <v-text-field
             type='password'
             background-color='#c9ebff'
           shaped
            v-model="password"
            label="Password"
            filled
           ></v-text-field>
            </v-col>
              <v-col
            cols="12"
            sm="6"
            >

           <v-text-field
             type='password'
            v-model="confirmPassword"
            background-color='#c9ebff'
           shaped
            label="Re-type Password"
            filled
           ></v-text-field>
            </v-col>
        </v-row>
        </v-container>
           <Loading v-if="Loading" class="ma-2"/>
        <div class="formcard">
        <v-btn
        @click="CreateAccount()"
        large
        dark
        class="pl-10 pr-10"
        color="#1c63ba"
        >
        submit
        </v-btn>
        <p class="mt-6">Don’t have an account yet ?<strong class="signup" @click="navigate('login')">Sign In</strong> </p>
        <p><strong> or via social</strong> </p>
        <div class="oauth">
         <v-btn
         @click="loginWithGoogle(i)"
         :key="index"
         v-for="(i, index) in icons"
          class="mx-1"
          dark
          :color="color[index]"
          fab
        >
          <v-icon>
            {{ i }}
          </v-icon>
        </v-btn>
        </div>
        </div>
        </v-form>
        </div>
          <p @click="navigate('home')" class="font-weight-thin underline">Back To Home</p>
          <v-dialog
            v-model="dialog"
            persistent
            max-width="290"
          >
      <v-card>
        <v-card-title class="headline">
          Use Google's location service?
        </v-card-title>
        <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
</template>
<script>
import {SignUp,GoogleAuth} from '../APIs'
import axios from "axios";
import Loading from '../components/Loading'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
export default {
        components:{ 
          VuePhoneNumberInput,
          Loading,
    },
      metaInfo: {
    title: `Sign Up`,
    meta: [
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'Create Account'}
    ]
  },
    mixins: [validationMixin],
    validations: {
      password: { required, },
      fullname: { required, },
      email: { required, }
    },
     data: () => ({
      results:null,
      formattedNumber:"",
       email:"",
       password:"",
       fullname:"",
       confirmPassword:"",
       phoneNumber:"",
       dialog: false,
      icons: [
        'mdi-facebook',
        'mdi-google',
      ],
      color:[
          '#1c63ba',
          'red'
      ]
    }), 
    computed:{
         Loading() {
          return this.$store.getters.Loading;
        },
        fullnameErrors () {
          const errors = []
        if (!this.$v.fullname.$dirty) return errors
          !this.$v.fullname.required && errors.push('name is required.')
          return errors
      },
        emailErrors () {
          const errors = []
        if (!this.$v.email.$dirty) return errors
          !this.$v.email.required && errors.push('email is required.')
          return errors
      },
    },
    methods:{
        async loginWithGoogle(payload){
       if(payload == 'mdi-facebook'){
         this.oauth()
         return
       }
       try{
        const googleUser = await this.$gAuth.signIn()
        let user = googleUser.getAuthResponse()
          this.$store.commit("setLoading", true)
          axios.put(`${GoogleAuth}`,{
            token: user.id_token
          })
          .then(res => {
            this.$store.commit("setUser", res.data.user)
            this.$store.commit("setToken", res.data.token)
            this.$router.push({name: "home"})
          })
           .catch(err => {
            console.log(err.response.data, "GoogleAuth: ERR")
            this.$store.commit("setSnackbar", true);
            this.$store.commit("setServerMessage", `Err: ${err.response.data.message}`)
          })
          .finally(()=>{
             this.$store.commit("setLoading", false)
          }) 
       }
       catch(e){
          console.log(e, 'err')
          this.$store.commit("setLoading", false)
       }
      },
       oauth(){
      this.$store.commit("setSnackbar", true)
      this.$store.commit("setServerMessage", "Not Available")
    },
        navigate(payload){
           this.$router.push({name:payload});
        },
        CreateAccount(){
          if(this.email == '' || this.fullname == '' || this.password == ''){
              this.$store.commit("setSnackbar", true)
          this.$store.commit("setServerMessage", "Fill All The Required Field")
          return
          }
          if(this.password != this.confirmPassword){
            this.$store.commit("setSnackbar", true)
            this.$store.commit("setServerMessage", "Password dont match")
            return
          }
       // this.dialog = true
          this.$store.commit("setLoading", true)
          axios.post(`${SignUp}`,{
            fullname:this.fullname,
            phoneNumber:this.formattedNumber,
            email:this.email,
            password:this.password
          })
          .then(res => {    
              console.log(res.data.message, "PPP") 
              this.$store.commit("setUser", res.data.user)
               this.$store.commit("setToken", res.data.token)
              if (this.$route.query.redirect) {
                this.$router.push({path:this.$route.query.redirect});
              } else {
              this.$router.push({name: "home"})
              }
              this.$store.commit("setSnackbar", true);
              this.$store.commit("setServerMessage", "Account Created Successfully")
          })
          .catch(err => {
            console.log(err.response.data, "Signup: ERR")
            this.$store.commit("setSnackbar", true);
            this.$store.commit("setServerMessage", `Err: ${err.response.data.message}`)
          })
          .finally(()=>{
             this.$store.commit("setLoading", false)
          })
        }
    },
       watch: {
      results (val) {
        if (val){
            console.log(val.formattedNumber)
            if(val.formattedNumber){
                this.formattedNumber = val.formattedNumber
            }
        }
      },
       }
}
</script>
<style scoped>
.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.C1{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.logincard{
  width: 600px;
  padding: 29px;
  border-radius: 20px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  margin-bottom: 50px;
}
.signin{
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #1156aa;
}
.forgetPassword{
    text-align:right;
}
.signup{
    color: #d9ab07;
     cursor: pointer;
}
.text{
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  text-decoration-line: underline;
  color: #1c63ba;
}
.formcard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.oauth{
    display: flex;
    margin: 0px;
}
.underline{
  text-decoration-line: underline;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
.logincard{
  width: 350px;
  padding: 29px;
  border-radius: 20px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
}
}
</style>